/* global console Excel window BroadcastChannel localStorage*/

import { apiGetMnemonic, apiSetMnemonic, askMelodyApi, docOcr } from "../api/functionsEndpoints";
import { cleanWorksheetId } from "../taskpane/utils/utils";
import { getOrCreateWorkbookId, storeLineageData } from "../utils";
const documentChannel = new BroadcastChannel("document-channel");

/**
 * Extracts specific data from financial documents using the scope ID and query.
 * @customfunction
 * @param {string} scopeId scopeId for melody search.
 * @param {string} query query for melody search.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @requiresAddress
 * @returns {string} Melody search result.
 */
export async function askMelody(scopeId, query, invocation) {
  try {
    if (!localStorage.getItem("accessToken")) {
      let value = "";
      await Excel.run({ delayForCellEdit: true }, async (context) => {
        const address = invocation.address;
        const addressParts = address.split("!");
        const sheet = context.workbook.worksheets.getItem(addressParts[0]);

        const range = sheet.getRange(address);
        range.load("values");
        await context.sync();
        value = range.values[0];
      });
      return value;
    }
    if (!scopeId) {
      return `ScopeId is required!!!`;
    }
    if (!query) {
      return `Query is required!!!`;
    }

    const response = await askMelodyApi(scopeId, query);

    await Excel.run({ delayForCellEdit: true }, async (context) => {
      context.workbook.worksheets.getActiveWorksheet();
      const address = invocation.address;
      await context.sync();
      const addressParts = address.split("!");
      const cellReference = addressParts.length > 1 ? addressParts[1] : address;
      if (addressParts[1]) {
        window.cellAddress = addressParts[1];
      }
      const sheet = context.workbook.worksheets.getItem(addressParts[0]);

      const range = sheet.getRange(address);

      if (response.status !== "FAIL") {
        range.format.autofitColumns();
        await context.sync();
        sheet.load("id");
        await context.sync();
        const sheetId = cleanWorksheetId(sheet.id);
        if (response.lineage && response.lineage.bounding_box?.length && response.lineage.page_number) {
          console.log("here askmelody");

          storeLineageData({
            lineage: response.lineage,
            sheetId,
            scopeId,
            cellReference,
            workbookId: getOrCreateWorkbookId(),
          });
          documentChannel.postMessage({
            type: "LINEAGE_CREATED",
            data: { cellCoordinate: cellReference, lineage: response.lineage, scopeId },
          });
        }
      }
    });

    return response.message || "Oops something went wrong!!!";
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: something went wrong!!!`;
  }
}
/**
 * Extracts data from a specific area in a document using the scope ID, page number, and coordinates.
 * @customfunction OCR
 * @param {string} scopeId scopeId The unique ID of the document..
 * @param {number[][]} coordinates An Excel array or range specifying the page number and coordinates (left, top, right, bottom).
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @requiresAddress
 * @returns {string} Ocr.
 */
export async function ocr(scopeId, coordinates, invocation) {
  try {
    if (!localStorage.getItem("accessToken")) {
      let value = "";
      await Excel.run({ delayForCellEdit: true }, async (context) => {
        const address = invocation.address;
        const addressParts = address.split("!");
        const sheet = context.workbook.worksheets.getItem(addressParts[0]);

        const range = sheet.getRange(address);
        range.load("values");
        await context.sync();
        value = range.values[0];
      });
      return value;
    }
    if (!scopeId) {
      return `ScopeId is required!!!`;
    }
    if (!coordinates) {
      return `Coordinates is required!!!`;
    }

    const coords = coordinates[0];
    if (coords.length < 5) {
      return "missing Coordinates!!";
    }
    const address = invocation.address;
    // Sync the context to fetch the cell properties

    const body = {
      page_number: coords[0],
      bounding_box: coords.slice(1, coords.length),
      scopeId,
    };
    const response = await docOcr(body);
    if (response.data) {
      await Excel.run({ delayForCellEdit: true }, async (context) => {
        const addressParts = address.split("!");
        const cellReference = addressParts[1];
        const sheet = context.workbook.worksheets.getItem(addressParts[0]);
        sheet.load("id");
        await context.sync();
        const sheetId = cleanWorksheetId(sheet.id);

        const lineage = {
          bounding_box: body.bounding_box,
          page_number: body.page_number,
        };
        console.log("here docOcr");

        storeLineageData({
          lineage,
          sheetId,
          scopeId,
          workbookId: getOrCreateWorkbookId(),
          cellReference,
        });
        documentChannel.postMessage({
          type: "LINEAGE_CREATED",
          data: {
            cellCoordinate: cellReference,
            lineage,
            scopeId,
          },
        });
      });
    }
    return response.data || response.error || "Something went wrong!!!";
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: something went wrong!!!`;
  }
}

/**
 * Assigns a specific value from coordinates within a document to a defined knowledge graph with a specific Mnemonic name.
 * @customfunction
 * @param {string} scopeId The unique ID of the document.
 * @param {string} knowledgeGraph Knowledge Graph.
 * @param {string} mnemonic  Name of Mnemonic.
 * @param {string} value Value of Mnemonic.
 * @param {number[][]} coordinates An Excel array or range specifying the page number and coordinates (left, top, right, bottom).
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @requiresAddress
 * @returns {string} Mnemonic.
 */
export async function set(scopeId, knowledgeGraph, mnemonic, value, coordinates, invocation) {
  try {
    if (!localStorage.getItem("accessToken")) {
      let value = "";
      await Excel.run({ delayForCellEdit: true }, async (context) => {
        const address = invocation.address;
        const addressParts = address.split("!");
        const sheet = context.workbook.worksheets.getItem(addressParts[0]);

        const range = sheet.getRange(address);
        range.load("values");
        await context.sync();
        value = range.values[0];
      });
      return value;
    }
    if (!scopeId) {
      return `ScopeId is required!!!`;
    }
    if (!knowledgeGraph) {
      return `knowledgeGraph is required!!!`;
    }
    if (!mnemonic) {
      return `mnemonic is required!!!`;
    }

    if (!coordinates) {
      return `Coordinates is required!!!`;
    }

    const coords = coordinates[0];
    if (coords.length < 5) {
      return "missing Coordinates!!";
    }
    if (!value) {
      return "Value is required";
    }
    const body = {
      page_number: coords[0],
      bounding_box: coords.slice(1, coords.length),
      scopeId,
      knowledgeGraph,
      mnemonic,
      value,
    };
    const response = await apiSetMnemonic(body);
    if (response.status === "success") {
      await Excel.run({ delayForCellEdit: true }, async (context) => {
        const address = invocation.address;

        const addressParts = address.split("!");
        const cellReference = addressParts[1];
        const sheet = context.workbook.worksheets.getItem(addressParts[0]);
        sheet.load("id");
        await context.sync();
        const sheetId = cleanWorksheetId(sheet.id);

        const lineage = {
          bounding_box: body.bounding_box,
          page_number: body.page_number,
        };
        console.log("here apiSetMnemonic");
        storeLineageData({
          lineage,
          sheetId,
          scopeId,
          workbookId: getOrCreateWorkbookId(),
          cellReference,
        });

        documentChannel.postMessage({
          type: "LINEAGE_CREATED",
          data: {
            cellCoordinate: cellReference,
            lineage: {
              bounding_box: body.bounding_box,
              page_number: body.page_number,
            },
            scopeId,
          },
        });
      });
    }
    return response.status === "success" ? `Created ${mnemonic} mnemonic.` : "Error: something went wrong!!!";
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: something went wrong!!!`;
  }
}

/**
 * Retrieves the value associated with a specific mnemonic in the knowledge graph for a given document.
 * @customfunction
 * @param {string} scopeId The unique ID of the document.
 * @param {string} knowledgeGraph Knowledge Graph.
 * @param {string} mnemonic Name of Mnemonic.
 * @param {CustomFunctions.Invocation} invocation Invocation object.
 * @requiresAddress
 * @returns {string[][]} MnemonicList.
 */
export async function get(scopeId, knowledgeGraph, mnemonic, invocation) {
  try {
    if (!localStorage.getItem("accessToken")) {
      let value = "";
      await Excel.run({ delayForCellEdit: true }, async (context) => {
        const address = invocation.address;
        const addressParts = address.split("!");
        const sheet = context.workbook.worksheets.getItem(addressParts[0]);

        const range = sheet.getRange(address);
        range.load("values");
        await context.sync();
        value = range.values[0];
      });
      return value;
    }
    if (!scopeId) {
      return `ScopeId is required!!!`;
    }
    if (!knowledgeGraph) {
      return `knowledgeGraph is required!!!`;
    }
    if (!mnemonic) {
      return `mnemonic is required!!!`;
    }

    const body = {
      scopeId,
      knowledgeGraph,
      mnemonic,
    };
    const response = await apiGetMnemonic(body);
    let arrayData = [];

    response.data.forEach((element) => {
      arrayData.push(element.value);
    });
    await Excel.run({ delayForCellEdit: true }, async (context) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      const address = invocation.address;
      const range = sheet.getRange(address);

      range.format.wrapText = true;
      context.sync();
    });
    return response.status === "success" ? [arrayData] : `Error: something went wrong!!!`;
  } catch (error) {
    console.error(error, "erororororor");
    return `Error: something went wrong!!!`;
  }
}

CustomFunctions.associate("ASKMELODY", askMelody);
CustomFunctions.associate("OCR", ocr);
CustomFunctions.associate("SET", set);
CustomFunctions.associate("GET", get);